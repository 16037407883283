export const content = [
  {
    id: 1,
    question: "What is the typical timeline for developing a website?",
    answer:
      "The timeline for developing a website can vary depending on the complexity and specific requirements of the project. Generally, a basic website can take anywhere from 4 to 6 weeks, while more complex sites with custom features might take 3 to 6 months. This includes stages like planning, design, development, testing, and deployment.",
  },
  {
    id: 2,
    question: "How can a web audit benefit my business?",
    answer:
      "A web audit thoroughly examines your website's performance, usability, and SEO (Search Engine Optimisation). It helps identify areas for improvement, such as slow loading times, broken links, or poor mobile responsiveness. By addressing these issues, we can enhance your website's user experience, improve search engine rankings, and ultimately drive more traffic and conversions.",
  },
  {
    id: 3,
    question: "Will my website be mobile-friendly?",
    answer:
      "Yes, all websites we develop are designed to be fully responsive, meaning they will work seamlessly on all devices, including desktops, tablets, and smartphones. This ensures a consistent and user-friendly experience for all visitors, regardless of the device they use.",
  },
  {
    id: 4,
    question: "How does SEO improve my digital presence?",
    answer:
      "SEO (Search Engine Optimisation) involves optimising your website to rank higher in search engine results. This includes keyword research, on-page optimization, and building high-quality backlinks. By improving your SEO, we help increase your website's visibility, attract more organic traffic, and establish a stronger online presence for your business.",
  },
];

export const headingFAQ = [
  "Search Engine Optimisation",
  "AI & Automation",
  "UI & UX",
  "Web Development",
  "Branding & Graphic design",
];

export const seoFAQs = [
  {
    id: 1,
    question: "What is SEO and why is it important?",
    answer:
      "SEO (Search Engine Optimization) involves optimizing your website to improve its visibility on search engines like Google. It helps drive organic traffic to your site, increasing your chances of attracting potential customers.",
  },
  {
    id: 2,
    question: "What SEO services do you offer?",
    answer:
      "Our SEO services include keyword research, on-page optimization, link building, content creation, and performance tracking to improve your search engine rankings.",
  },
  {
    id: 3,
    question: "How long does it take to see results from SEO?",
    answer:
      "SEO is a long-term strategy, and it can take several months to start seeing significant results. However, the benefits are lasting and can provide a steady stream of organic traffic over time.",
  },
  {
    id: 4,
    question: "Do you provide regular SEO reports?",
    answer:
      "Yes, we provide detailed SEO reports that track your website's performance, keyword rankings, and other important metrics to keep you informed of your progress.",
  },
];

export const aiAutomationFAQs = [
  {
    id: 1,
    question: "What AI and automation services do you offer?",
    answer:
      "We offer a range of AI and automation services including chatbots, predictive analytics, process automation, and personalized recommendations to help streamline your business operations.",
  },
  {
    id: 2,
    question: "How can AI benefit my business?",
    answer:
      "AI can help improve efficiency, reduce costs, and provide valuable insights by automating repetitive tasks, analyzing large datasets, and enhancing customer interactions.",
  },
  {
    id: 3,
    question: "Is AI suitable for small businesses?",
    answer:
      "Yes, AI solutions can be tailored to fit the needs and budget of small businesses, providing significant benefits without requiring large investments.",
  },
];

export const uiUxFAQs = [
  {
    id: 1,
    question: "What is the difference between UI and UX?",
    answer:
      "UI (User Interface) refers to the visual elements of a product, such as buttons and icons, while UX (User Experience) focuses on the overall feel and ease of use of the product.",
  },
  {
    id: 2,
    question: "How do you ensure a good user experience?",
    answer:
      "We conduct thorough research and testing to understand user needs and behaviors. We then create intuitive and visually appealing designs that provide a seamless user experience.",
  },
  {
    id: 3,
    question: "Can you improve the UI/UX of my existing website or app?",
    answer:
      "Yes, we can analyze your current website or app and suggest improvements to enhance its usability and visual appeal.",
  },
];

export const webDevelopmentFAQs = [
  {
    id: 1,
    question: "What types of websites do you develop?",
    answer:
      "We develop a variety of websites including e-commerce sites, corporate websites, blogs, and portfolio sites, all tailored to meet your specific business needs.",
  },
  {
    id: 2,
    question: "Do you offer website maintenance services?",
    answer:
      "Yes, we offer ongoing website maintenance services to ensure your site remains updated, secure, and fully functional.",
  },
  {
    id: 3,
    question: "How long does it take to build a website?",
    answer:
      "The timeline for building a website depends on its complexity and features. A basic website can take a few weeks, while a more complex site may take several months.",
  },
  {
    id: 4,
    question: "Will my website be mobile-friendly?",
    answer:
      "Absolutely! We ensure that all websites we develop are responsive and optimized for mobile devices.",
  },
];

export const brandingAndGraphicDesignFAQs = [
  {
    id: 1,
    question: "What is branding and why is it important?",
    answer:
      "Branding involves creating a unique identity for your business through visual elements like logos, colors, and typography. It helps establish a memorable and consistent image that differentiates you from competitors and builds customer loyalty.",
  },
  {
    id: 2,
    question: "What does your graphic design service include?",
    answer:
      "Our graphic design service includes creating logos, business cards, brochures, posters, social media graphics, and other marketing materials that align with your brand's identity.",
  },
  {
    id: 3,
    question: "How long does it take to complete a branding project?",
    answer:
      "The timeline for a branding project can vary depending on the complexity and scope. Typically, it can take anywhere from a few weeks to a couple of months.",
  },
  {
    id: 4,
    question: "Can you redesign my existing logo?",
    answer:
      "Yes, we can redesign your existing logo to give it a fresh and modern look while maintaining the essence of your brand.",
  },
];
