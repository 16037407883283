/* eslint-disable no-unused-vars */
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/system";
import Logo1 from "../assets/logoCollection/Apple_logo_grey.svg.png";
import Logo2 from "../assets/logoCollection/ChatGPT-Logo.svg";
import Logo3 from "../assets/logoCollection/dotnet-grey-small.svg";
import Logo4 from "../assets/logoCollection/make-seeklogo-2-small.png";
import Logo5 from "../assets/logoCollection/powerautomate_logo_icon_248796.png";
import Logo6 from "../assets/logoCollection/react-svgrepo-com.svg";
import Logo7 from "../assets/logoCollection/wordpress.png";

const whiteLogos = [Logo1, Logo2, Logo3, Logo4, Logo5, Logo6, Logo7];

// const whiteLogos = [
//   'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg',
//   'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
//   'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
//   'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
//   'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
//   'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
// ];

const darkLogos = [
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg",
];

const logoStyle = {
  width: "80px",
  height: "80px",
  margin: "0 32px",
  opacity: 0.7,
  objectFit: "contain",
  padding: "1rem",
};
const logoStyleNoPadding = {
  width: "80px",
  height: "80px",
  margin: "0 32px",
  opacity: 0.7,
  objectFit: "contain",
};

export default function LogoCollection() {
  const theme = useTheme();
  const logos = theme.palette.mode === "light" ? darkLogos : whiteLogos;

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        sx={{ color: "text.secondary" }}
      >
        Some of the technologies we specialise in
      </Typography>
      <Grid container sx={{ justifyContent: "center", mt: 0.5, opacity: 0.6 }}>
        {whiteLogos.map((logo, index) => {
          return (
            <Grid item key={index}>
              <img
                src={logo}
                alt={`Fake company number ${index + 1}`}
                style={logo.includes("small") ? logoStyleNoPadding : logoStyle}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
