/* eslint-disable react/react-in-jsx-scope */
const EmailAddress = "info@archerweb.com";
export const privacyPolicy = (
  <div>
    <p>
      <strong>Privacy Policy</strong>
    </p>
    <p>
      <strong>Effective Date:</strong> 01 September 2024
    </p>
    <p>
      <strong>Archer Web Solutions</strong> {`("we," "us," or "our")`} is
      committed to protecting your privacy. This Privacy Policy explains how we
      collect, use, disclose, and safeguard your information when you visit our
      website [<a href="http://www.archerweb.com">www.archerweb.co.uk</a>]
      {` (the "Site")`}. Please read this privacy policy carefully. If you do
      not agree with the terms of this privacy policy, please do not access the
      site.
    </p>
    <p>
      <strong>1. Information We Collect</strong>
    </p>
    <p>
      We may collect information about you in a variety of ways. The information
      we may collect on the Site includes:
    </p>
    <ul>
      <li>
        <strong>Personal Data:</strong> Personally identifiable information,
        such as your name, shipping address, email address, and telephone
        number, and demographic information, such as your age, gender, hometown,
        and interests, that you voluntarily give to us when you register with
        the Site or when you choose to participate in various activities related
        to the Site, such as online chat and message boards.
      </li>
      <li>
        <strong>Derivative Data:</strong> Information our servers automatically
        collect when you access the Site, such as your IP address, your browser
        type, your operating system, your access times, and the pages you have
        viewed directly before and after accessing the Site.
      </li>
      <li>
        <strong>Financial Data:</strong> Financial information, such as data
        related to your payment method (e.g., valid credit card number, card
        brand, expiration date) that we may collect when you purchase, order,
        return, exchange, or request information about our services from the
        Site.
      </li>
      <li>
        <strong>Mobile Device Data:</strong> Device information, such as your
        mobile device ID, model, and manufacturer, and information about the
        location of your device, if you access the Site from a mobile device.
      </li>
    </ul>
    <p>
      <strong>2. Use of Your Information</strong>
    </p>
    <p>
      Having accurate information about you permits us to provide you with a
      smooth, efficient, and customized experience. Specifically, we may use
      information collected about you via the Site to:
    </p>
    <ul>
      <li>Create and manage your account.</li>
      <li>
        Process your transactions and send you related information, including
        purchase confirmations and invoices.
      </li>
      <li>Email you regarding your account or order.</li>
      <li>
        Fulfill and manage purchases, orders, payments, and other transactions
        related to the Site.
      </li>
      <li>Improve the Site and our services.</li>
      <li>
        Monitor and analyze usage and trends to improve your experience with the
        Site.
      </li>
      <li>Notify you of updates to the Site.</li>
      <li>Offer new products, services, and/or recommendations to you.</li>
      <li>Perform other business activities as needed.</li>
    </ul>
    <p>
      <strong>3. Disclosure of Your Information</strong>
    </p>
    <p>
      We may share information we have collected about you in certain
      situations. Your information may be disclosed as follows:
    </p>
    <ul>
      <li>
        <strong>By Law or to Protect Rights:</strong> If we believe the release
        of information about you is necessary to respond to legal process, to
        investigate or remedy potential violations of our policies, or to
        protect the rights, property, and safety of others, we may share your
        information as permitted or required by any applicable law, rule, or
        regulation.
      </li>
      <li>
        <strong>Third-Party Service Providers:</strong> We may share your
        information with third parties that perform services for us or on our
        behalf, including payment processing, data analysis, email delivery,
        hosting services, customer service, and marketing assistance.
      </li>
      <li>
        <strong>Business Transfers:</strong> We may share or transfer your
        information in connection with, or during negotiations of, any merger,
        sale of company assets, financing, or acquisition of all or a portion of
        our business to another company.
      </li>
      <li>
        <strong>Affiliates:</strong> We may share your information with our
        affiliates, in which case we will require those affiliates to honor this
        Privacy Policy. Affiliates include our parent company and any
        subsidiaries, joint venture partners, or other companies that we control
        or that are under common control with us.
      </li>
      <li>
        <strong>Other Third Parties:</strong> We may share your information with
        advertisers and investors for the purpose of conducting general business
        analysis. We may also share your information with such third parties for
        marketing purposes, as permitted by law.
      </li>
    </ul>
    <p>
      <strong>4. Security of Your Information</strong>
    </p>
    <p>
      We use administrative, technical, and physical security measures to help
      protect your personal information. While we have taken reasonable steps to
      secure the personal information you provide to us, please be aware that
      despite our efforts, no security measures are perfect or impenetrable, and
      no method of data transmission can be guaranteed against any interception
      or other type of misuse.
    </p>
    <p>
      <strong>5. Policy for Children</strong>
    </p>
    <p>
      We do not knowingly solicit information from or market to children under
      the age of 13. If we learn that we have collected personal information
      from a child under age 13 without verification of parental consent, we
      will delete that information as quickly as possible. If you believe we
      might have any information from or about a child under 13, please contact
      us at {EmailAddress}.
    </p>
    <p>
      <strong>6. Changes to This Privacy Policy</strong>
    </p>
    <p>
      We may update this Privacy Policy from time to time in order to reflect,
      for example, changes to our practices or for other operational, legal, or
      regulatory reasons. We will notify you of any changes by posting the new
      Privacy Policy on the Site. You are advised to review this Privacy Policy
      periodically for any changes. Changes to this Privacy Policy are effective
      when they are posted on this page.
    </p>
    <p>
      <strong>7. Contact Us</strong>
    </p>
    <p>
      If you have questions or comments about this Privacy Policy, please
      contact us at:
    </p>
    <p>
      <strong>Archer Web Solutions</strong>
      {/* <br />
    [Address]
    <br /> */}
      {/* [City, State, ZIP Code] */}
      <br />
      {EmailAddress}
      <br />
      {/* [Phone Number] */}
    </p>
  </div>
);
