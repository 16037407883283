/* eslint-disable react/prop-types */
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import LogoNB from "../assets/logo-no-background.png";
import LogoNB2 from "../assets/logo-no-background-blue.png";
import scrollToSection from "../hooks/scrollToSection";
import useDeviceType from "../hooks/useDeviceType";

export default function SiteLogo({ img, boxWidth }) {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const deviceType = useDeviceType();

  const navigateAndScroll = (path, id) => {
    navigate(path);
    setTimeout(() => {
      scrollToSection(id);
    }, 100);
  };

  return (
    <Box
      sx={{
        width: {
          xs:
            deviceType === "mobile"
              ? boxWidth.mobile
              : deviceType === "desktop"
              ? boxWidth.desktop
              : boxWidth.tablet,
          // sm: isMobile ? boxWidth.mobile : !isMobile ? boxWidth.desktop : "60%",
        },
      }}
    >
      <img
        onClick={() => {
          pathname.includes("contact")
            ? navigateAndScroll("/", "hero")
            : scrollToSection("hero");
        }}
        src={img === "LogoNB" ? LogoNB : LogoNB2}
        alt="Archer Web Solutions Logo"
        style={{
          cursor: "pointer",
          // width: imgWidth ? imgWidth : "100%",
          maxWidth: "100%",
          height: "auto",
          display: "block",
        }}
      />
    </Box>
  );
}
