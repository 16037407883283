import { pages } from "./pages";

export const columnOne = {
  heading: "Pages",
  children: pages,
};
export const columnTwo = {
  heading: "Legal",
  children: [
    {
      id: 1,
      label: "Terms",
      link: "",
    },
    {
      id: 2,
      label: "Privacy",
      link: "",
    },
    {
      id: 3,
      label: "Contact",
      link: "",
    },
  ],
};
export const columnThree = {
  heading: "Legal",
  children: [
    {
      id: 1,
      label: "Terms",
      link: "/terms",
    },
    {
      id: 2,
      label: "Privacy",
      link: "/privacy",
    },
    {
      id: 3,
      label: "Contact",
      link: "/contact",
    },
  ],
};
