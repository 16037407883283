import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Container from "@mui/material/Container";
import LandingPage from "./LandingPage";
import "./styles/index.scss";
import ContactPage from "./ContactPage";
import Privacy from "./Privacy";
import Terms from "./Terms";

export default function App() {
  return (
    <Container maxWidth="sm" className="App--Wrapper">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </Container>
  );
}
