import * as React from "react";

export const termsOfService = (
  <>
    <p>
      <strong>1. Acceptance of Terms</strong>
    </p>
    <p>
      By accessing and using [Your Website Name] {`("the Website")`}, you accept
      and agree to be bound by the terms and provision of this agreement. If you
      do not agree to abide by these terms, please do not use this Website.
    </p>
    <p>
      <strong>2. Description of Service</strong>
    </p>
    <p>
      [Your Website Name] provides [describe the services or products you
      offer]. You understand and agree that the Service is provided {`"as is"`}{" "}
      and that [Your Website Name] assumes no responsibility for the timeliness,
      deletion, misdelivery, or failure to store any user communications or
      personalization settings.
    </p>
    <p>
      <strong>3. User Obligations</strong>
    </p>
    <p>
      You agree to use the Website only for lawful purposes. You are prohibited
      from posting or transmitting through the Website any material that
      violates or infringes in any way upon the rights of others, that is
      unlawful, threatening, abusive, defamatory, invasive of privacy or
      publicity rights, vulgar, obscene, profane, or otherwise objectionable.
    </p>
    <p>
      <strong>4. Intellectual Property</strong>
    </p>
    <p>
      All content included on the Website, such as text, graphics, logos,
      images, and software, is the property of [Your Website Name] or its
      content suppliers and protected by international copyright laws. The
      compilation of all content on this site is the exclusive property of [Your
      Website Name].
    </p>
    <p>
      <strong>5. Limitation of Liability</strong>
    </p>
    <p>
      In no event shall [Your Website Name], nor its directors, employees,
      partners, agents, suppliers, or affiliates, be liable for any indirect,
      incidental, special, consequential, or punitive damages, including without
      limitation, loss of profits, data, use, goodwill, or other intangible
      losses, resulting from (i) your use or inability to use the Service; (ii)
      any unauthorized access to or use of our servers and/or any personal
      information stored therein.
    </p>
    <p>
      <strong>6. Modifications to Service</strong>
    </p>
    <p>
      [Your Website Name] reserves the right to modify or discontinue,
      temporarily or permanently, the Service (or any part thereof) with or
      without notice at any time. You agree that [Your Website Name] shall not
      be liable to you or to any third party for any modification, suspension,
      or discontinuance of the Service.
    </p>
    <p>
      <strong>7. Governing Law</strong>
    </p>
    <p>
      These Terms shall be governed and construed in accordance with the laws of
      [Your Country/State], without regard to its conflict of law provisions.
    </p>
    <p>
      <strong>8. Contact Information</strong>
    </p>
  </>
);
