/* eslint-disable no-constant-binary-expression */
/* eslint-disable no-unused-vars */
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Sitemark from "./SitemarkIcon";
import scrollToSection from "../hooks/scrollToSection";
import { pages } from "../consts/pages";
import SiteLogo from "./SiteLogo";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: "blur(24px)",
  border: "1px solid",
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: "8px 12px",
}));

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const navigateAndScroll = (path, id) => {
    navigate(path);
    setTimeout(() => {
      scrollToSection(id);
    }, 100);
  };

  const handleMobileMenu = (link) => {
    setOpen(false);
    ["/contact", "/privacy", "/terms"].includes(pathname)
      ? navigateAndScroll("/", link)
      : scrollToSection(link);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        mt: 10,
      }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box
            sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0 }}
            style={{ gap: "1rem" }}
          >
            <SiteLogo
              img="LogoNB2"
              boxWidth={{ mobile: "45%", tablet: "20%", desktop: "15%" }}
            />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {pages?.map((page, i) => {
                if (page?.sx) {
                  return (
                    <Button
                      key={i}
                      variant="text"
                      color="info"
                      size="small"
                      sx={{ minWidth: 0 }}
                      onClick={() => {
                        ["/contact", "/privacy", "/terms"].includes(pathname)
                          ? navigateAndScroll("/", page.link)
                          : scrollToSection(page.link);
                      }}
                    >
                      {page.label}
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      key={i}
                      variant="text"
                      color="info"
                      size="small"
                      onClick={() =>
                        ["/contact", "/privacy", "/terms"].includes(pathname)
                          ? navigateAndScroll("/", page.link)
                          : scrollToSection(page.link)
                      }
                    >
                      {page.label}
                    </Button>
                  );
                }
              })}
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 1,
              alignItems: "center",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => navigate("/contact")}
              className="AppBar__primaryButton"
            >
              Get in touch
            </Button>
          </Box>
          <Box sx={{ display: { sm: "flex", md: "none" } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: "background.default" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                {pages?.map((page, i) => {
                  return (
                    <MenuItem
                      key={i}
                      onClick={() => handleMobileMenu(page.link)}
                    >
                      {page.label}
                    </MenuItem>
                  );
                })}
                <MenuItem>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={() => navigate("/contact")}
                  >
                    Contact
                  </Button>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
