/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { termsOfService } from "./consts/terms";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import getMPTheme from "./theme/getMPTheme";
import AppAppBar from "./components/AppAppBar";

export default function Terms() {
  const [mode, setMode] = useState("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });
  return (
    <>
      <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
        <CssBaseline enableColorScheme />
        <AppAppBar />
        <Container
          id="terms"
          sx={{
            pt: { xs: 4, sm: 12 },
            pb: { xs: 8, sm: 16 },
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Box
            sx={{
              width: { sm: "100%", md: "60%" },
              textAlign: { sm: "left", md: "center" },
            }}
          >
            <Typography
              component="h2"
              variant="h4"
              gutterBottom
              sx={{ color: "text.primary" }}
            >
              Terms of Service
            </Typography>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              {termsOfService}
            </Typography>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
