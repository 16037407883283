/* eslint-disable no-unused-vars */
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AutoFixHighRoundedIcon from "@mui/icons-material/AutoFixHighRounded";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DrawIcon from "@mui/icons-material/Draw";

const items = [
  {
    icon: <DrawIcon />,
    title: "Branding & Graphic design",
    description:
      "Our graphic design and branding services help establish a strong visual identity for your business, from logos to marketing materials.",
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: "Web Development",
    description:
      "We build and maintain custom websites tailored to your business needs, ensuring they stay updated, secure, and fully functional.",
  },
  {
    icon: <PeopleAltIcon />,
    title: "UI & UX",
    subtitle: "User Interface & User Experience",
    description: `We create intuitive and visually appealing designs that offer a seamless user experience and reflect your brand's identity.`,
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: "AI & Automation",
    subtitle: "Artificial Intelligence",
    description:
      "Stay ahead with features that set new standards, addressing your evolving needs better than the rest.",
  },
  // {
  //   icon: <SupportAgentRoundedIcon />,
  //   title: 'Reliable support',
  //   description:
  //     'Count on our responsive customer support, offering assistance that goes beyond the purchase.',
  // },
  {
    icon: <QueryStatsRoundedIcon />,
    title: "SEO",
    subtitle: "Search Engine Optimisation",
    description:
      "We optimize your website to improve search engine rankings and boost your online visibility, driving more organic traffic to your site.",
  },
  {
    icon: <VisibilityIcon />,
    title: "Web Audit",
    description: `With our comprehensive website audit service, we identify and resolve issues affecting your site's performance, SEO, and user experience, ensuring your online presence is optimised for success.`,
  },
];

export default function Services() {
  return (
    <Box
      id="services"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: "grey.900",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Services
          </Typography>
          <Typography variant="body1" sx={{ color: "grey.400" }}>
            Explore our comprehensive services, including branding & graphic
            design, web development, UI & UX, AI & automation, and SEO, all
            designed to deliver exceptional value and results for your business.
          </Typography>
          <Typography
            component="h2"
            variant="body1"
            gutterBottom
            sx={{ color: "grey.400" }}
          >
            Check out our FAQ section for more information.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: "inherit",
                  p: 3,
                  height: "100%",
                  borderColor: "hsla(220, 25%, 25%, 0.3)",
                  backgroundColor: "grey.800",
                }}
              >
                <Box sx={{ opacity: "50%" }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: "medium" }}>
                    {item.title}
                  </Typography>
                  <Typography
                    gutterBottom
                    sx={{ fontWeight: "medium", color: "grey.400" }}
                  >
                    {item.subtitle}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey.400" }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
