/* eslint-disable no-unused-vars */
import React from "react";
import Contact from "./components/Contact";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import getMPTheme from "./theme/getMPTheme";
import AppAppBar from "./components/AppAppBar";
import Hero from "./components/Hero";

function ContactPage() {
  const [mode, setMode] = React.useState("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  return (
    <>
      <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
        <CssBaseline enableColorScheme />
        <AppAppBar />
        <div>
          <Contact />
        </div>
      </ThemeProvider>
    </>
  );
}

export default ContactPage;
