export const pages = [
  {
    id: 1,
    label: "Services",
    link: "services",
  },
  {
    id: 2,
    label: "Previous Work",
    link: "work",
  },
  {
    id: 3,
    label: "Pricing",
    link: "pricing",
  },
  {
    id: 4,
    label: "FAQs",
    link: "faq",
  },
];
