/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState } from "react";
import { TextField, Button, Container, Typography, Box } from "@mui/material";
import axios from "axios";

function Contact() {
  const token = process.env.REACT_APP_ACCESS_TOKEN;
  const table = process.env.REACT_APP_TABLE_NAME;
  const baseId = process.env.REACT_APP_BASE_ID;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    notes: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `https://api.airtable.com/v0/${baseId}/${table}`,
        {
          fields: {
            Name: formData.name,
            Email: formData.email,
            Phone: formData.phone,
            Notes: formData.notes,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container
      id="contact"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        id="contact-form"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          width: "100%",
          // color: "white",
          // bgcolor: "grey.900",
        }}
      >
        <Container
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Box
            sx={{
              width: { sm: "100%", md: "60%" },
              textAlign: { sm: "left", md: "center" },
            }}
          >
            <Typography variant="h4" component="h1" gutterBottom>
              Tell us about your project
            </Typography>

            <Typography variant="body1" sx={{ color: "grey.400" }} gutterBottom>
              {`We're excited to hear about your website development project! Whether it’s a personal blog, a cutting-edge online store, or a comprehensive corporate site, we're here to help. Fill out the form below with your project details, and let’s get started on building something extraordinary together!`}
            </Typography>

            <Typography variant="body1" sx={{ color: "grey.400" }}>
              Or drop us an email:{" "}
              <a href="mailto:info@archerweb.co.uk?subject=Website%20Enquiry">
                info@archerweb.co.uk
              </a>
            </Typography>
          </Box>

          <form onSubmit={handleSubmit} className="Contact__form">
            <TextField
              label="Full Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              margin="normal"
              variant="standard"
              fullWidth
              required
            />
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
              variant="standard"
              fullWidth
              required
            />
            <TextField
              label="Phone (optional)"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="standard"
            />
            <TextField
              label="Notes"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              placeholder="Provide a detailed explanation of your requirements"
              variant="standard"
              fullWidth
              multiline
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="Contact__button"
            >
              Submit
            </Button>
          </form>
        </Container>
      </Box>
    </Container>
  );
}

export default Contact;
